.App {
  text-align: center;
}
.mintContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.gif {
  width: 25vw;
  height: auto;
  margin-bottom: 4vh;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.55) 0px 15px 25px;
}
.logo__image {
  font-size: 3vw;
  margin-bottom: 2vh;
  font-family: "Courier New", Courier, monospace;
}
.mint__quantity {
  font-size: 1.5vw;
  margin-bottom: 2vh;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.MuiButton-label {
  font-size: 2.2vh !important;
  /* width: 6.7vw !important; */

  text-align: center !important;
  padding-left: 1vw !important;
  padding-right: 1vw !important;
}

@media screen and (max-width: 800px) {
  .gif {
    width: 60vw;
    height: auto;
  }
  .logo__image {
    font-size: 6vw;
  }
  .mint__quantity {
    font-size: 4vw;
    margin-bottom: 2vh;
  }
}

html {
  background: url(bg.png);
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
}
.jss1 .MuiDialogTitle-root {
  background-color: #007213 !important;
}
.MuiButton-containedPrimary {
  color: #fff;
  background-color: #007213 !important;
}
.makeStyles-root-1 .MuiDialogTitle-root,
.MuiButton-contained {
  background-color: #007213 !important;
  color: white !important;
}
